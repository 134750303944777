import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

export const useActions = <Keys extends string>(
    actions: Record<Keys, () => void>
): Record<Keys, () => void> => {
    const dispatch = useDispatch();

    const result = useMemo(() => {
        const remapped: Partial<Record<Keys, () => void>> = {};
        const entries: [string, () => void][] = Object.entries(actions);

        entries.forEach(([key, value]): void => {
            remapped[key as Keys] = (): void => dispatch(value());
        });

        return remapped as Record<Keys, () => void>;
    }, [actions]);

    return result;
};
