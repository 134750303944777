import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { Routes } from './routes';
import { store } from 'store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
//import { ComponentHelper } from 'components';
//import { ErrorBoundary } from 'components/feedback-indicators/error-boundary';
import '@fontsource/open-sans';
import '@fontsource/montserrat';

require('utils/array/extensions');

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            {/*<ErrorBoundary>*/}
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
                {/*<ComponentHelper />*/}
            {/*</ErrorBoundary>*/}
        </Provider>
    </StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
