/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPage } from 'typings';

const year = new Date().getFullYear();

export const Copyright: IPage = () => (
    <>
        <p>
            <small>
                &copy; HosHvims.dk {year}. Alle tekster og billeder her på siden
                er beskyttet af loven om ophavsret. Det er derfor ikke tilladt
                at &quot;låne&quot; eller kopiere hverken hele eller dele af
                tekster eller billeder uden først at have fået skriftlig
                tilladelse. Kontakt os hvis du har spørgsmål eller vil anvende
                vores materiale.
            </small>
        </p>
        <p>
            <small>
                Alt indhold på hjemmesiden er vejledende. HosHvims.dk tager
                intet ansvar for dine dyrs sundhed. Hvis du ser sygdomstegn hos
                dine dyr, bør du altid kontakte dyrlægen.
            </small>
        </p>
    </>
);
