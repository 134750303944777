import { PagePaths } from 'page-paths';
import { ReactNode } from 'react';
import { ValidLinks } from 'valid-links';

interface IUnderSites {
    site: ValidLinks;
    name: ReactNode;
    path: PagePaths;
    undersider?: IUnderSites[];
    hidden?: boolean;
}

export interface ISites {
    site: ValidLinks;
    name: ReactNode;
    path: PagePaths;
    undersider?: IUnderSites[];
    hidden?: boolean;
}

const hideInProd = process.env.NODE_ENV !== 'development';

export const sites: ISites[] = [
    {
        site: 'høns',
        name: 'HØNS',
        path: '/hoens.tsx',
        undersider: [
            {
                site: 'høns-hønseracer',
                name: 'HØNSERACER',
                path: '/hoens/hoens-hoenseracer.tsx',
            },
            {
                site: 'høns-trivsel',
                name: 'TRIVSEL',
                path: '/hoens/hoens-trivsel.tsx',
            },
            {
                site: 'høns-pasning',
                name: 'PASNING',
                path: '/hoens/hoens-pasning.tsx',
            },
            {
                site: 'høns-flokken',
                name: 'FLOKKEN',
                path: '/hoens/hoens-flokken.tsx',
            },
        ],
        hidden: hideInProd,
    },
    {
        site: 'kyllinger',
        name: 'KYLLINGER',
        path: '/kyllinger.tsx',
        undersider: [
            {
                site: 'kyllinger-pasning',
                name: 'PASNING',
                path: '/kyllinger/kyllinger-pasning.tsx',
            },
            {
                site: 'kyllinger-udvikling',
                name: 'UDVIKLING',
                path: '/kyllinger/kyllinger-udvikling.tsx',
            },
            {
                site: 'kyllinger-helbred',
                name: 'HELBRED',
                path: '/kyllinger/kyllinger-helbred.tsx',
            },
            { site: 'skruk', name: 'SKRUK', path: '/kyllinger/skruk.tsx' },
        ],
    },
    {
        site: 'æg',
        name: 'RUGEÆG',
        path: '/rugeaeg.tsx',
        undersider: [
            {
                site: 'rugevejledning',
                name: 'RUGEVEJLEDNING',
                path: '/aeg/rugevejledning.tsx',
            },
            {
                site: 'skrukhøne',
                name: 'SKRUKHØNE',
                path: '/aeg/skrukhoene.tsx',
            },
            {
                site: 'håndtering',
                name: 'HÅNDTERING',
                path: '/aeg/haandtering.tsx',
            },
            { site: 'lysning', name: 'LYSNING', path: '/aeg/lysning.tsx' },
        ],
    },
    {
        site: 'helbred',
        name: 'HELBRED',
        path: '/helbred.tsx',
        undersider: [
            {
                site: 'kosttilskud',
                name: 'KOSTTILSKUD',
                path: '/helbred/kosttilskud.tsx',
            },
            { site: 'utøj', name: 'UTØJ', path: '/helbred/utoej.tsx' },
            {
                site: 'sygdomme',
                name: 'SYGDOMME',
                path: '/helbred/sygdomme.tsx',
            },
            {
                site: 'sygdomsbestemmelse',
                name: 'SYGDOMSBESTEMMELSE',
                path: '/helbred/sygdomsbestemmelse.tsx',
            },
        ],
        hidden: hideInProd,
    },
    {
        site: 'demo',
        name: 'DEMO',
        path: '/demo.tsx',
        hidden: hideInProd,
    },
    {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        site: 'fejlside',
        name: 'FEJLSIDE',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        path: '/fejl.tsx',
        hidden: hideInProd,
    },
];
