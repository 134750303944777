import { FC, lazy, Suspense, useEffect } from 'react';
import styles from 'components/structure/layout/layout.content.module.scss';
import { ISites } from '../layout/sites';

interface IMarkdownFile {
    site: Pick<ISites, 'path'>;
}

export const MarkdownFile: FC<IMarkdownFile> = ({ site }: IMarkdownFile) => {
    const Indhold = lazy(() =>
        import(`../../../pages${site.path}`)
            // eslint-disable-next-line @typescript-eslint/naming-convention
            .then(({ Indhold, settings }) => ({
                default: Indhold,
                settings,
            }))
            .catch(() =>
                import('../../../pages/fejlside').then(
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    ({ Indhold, settings }) => ({
                        default: Indhold,
                        settings,
                    })
                )
            )
    );

    const titel = '';
    const beskrivelse = '';

    useEffect(() => {
        const title = titel || 'Hos Hvims';
        const doesTitleContainName = title.includes('Hos Hvims');

        document.title = doesTitleContainName ? title : `${title} – Hos Hvims`;

        const description = document.querySelector(
            'head > meta[name="description"]'
        ) as HTMLMetaElement;

        if (description) {
            description.content =
                beskrivelse || 'Hvims kyllinger og rugeæg fra landet';
        }
    }, [Indhold, titel, beskrivelse]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <Suspense fallback={<>...</>}>
                    <Indhold />
                </Suspense>
            </div>
        </div>
    );
};
