import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { IMarkdownFile } from './typings';

export const stateScope = 'markdownFile';

interface IMarkdownFileState {
    loading: string[];
    failed: string[];
    markdownFiles: {
        [recipeUri: string]: IMarkdownFile;
    };
}

const initialState: IMarkdownFileState = {
    loading: [],
    failed: [],
    markdownFiles: {},
};

export const slice = createSlice({
    name: stateScope,
    initialState,
    reducers: {
        loadMarkdownFile: (state, action: PayloadAction<string>): void => {
            state.loading.push(action.payload);
        },
        failedMarkdownFile: (state, action: PayloadAction<string>): void => {
            state.loading = state.loading.filter(
                (uri) => uri !== action.payload
            );
            state.failed.push(action.payload);
        },
        addMarkdownFile: (
            state,
            action: PayloadAction<IMarkdownFile>
        ): void => {
            state.loading = state.loading.filter(
                (uri) => uri !== action.payload.uri
            );
            state.markdownFiles[action.payload.uri] = action.payload;
        },
    },
});

export const { reducer, actions } = slice;

export const {
    addMarkdownFile,
    loadMarkdownFile,
    failedMarkdownFile,
} = actions;

export const getState = (state: RootState): IMarkdownFileState =>
    state[stateScope];

export const reduced = { [stateScope]: reducer };
