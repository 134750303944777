import { ReactChild, useEffect, useRef } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IPage, IRouteProps } from 'typings';
//import ReactGA from 'react-ga4';
import { Layout } from 'components/structure/layout';
import { MarkdownFile } from 'components/structure/markdown-file';
import { sites } from 'components/structure/layout/sites';

//ReactGA.initialize('G-BYTZ53Q7JZ');

const flatMappedSites = sites.flatMap((site) => [
    site,
    ...(site.undersider?.map((underside) => ({
        ...underside,
        hidden: underside.hidden || site.hidden,
    })) ?? []),
]);

const getComponent = (controller: string): ReactChild => {
    const site = flatMappedSites.find((site) => site.site === controller);

    if (site && !site.hidden) {
        return <MarkdownFile site={site} />;
    }

    if (controller) {
        return <MarkdownFile site={{ path: '/fejlside.tsx' }} />;
    }

    return <MarkdownFile site={{ path: '/forside.tsx' }} />;
};

const Component = (props: RouteComponentProps) => {
    const referrer = useRef<string>();
    const pathname = props.location?.pathname || '';
    const paths = pathname.split('/').filter(Boolean);
    const controller = paths?.[0];

    const path: IRouteProps['path'] = {
        paths,
        pathname,
        controller,
    };

    const dispatch = useDispatch();

    useEffect(() => {
        //dispatch(clearAllTooltips());

        document.documentElement.scrollTop = 0;

        /*if (path) {
            ReactGA.send({ hitType: "pageview", page: path.pathname });
        }*/

        return () => {
            referrer.current = window.location.href;
        };
    }, [dispatch, path]);

    const component = getComponent(controller);

    return <Layout path={path}>{component}</Layout>;
};

export const Routes: IPage = () => (
    <Switch>
        <Route
            path="*"
            exact
            component={(props: RouteComponentProps) => <Component {...props} />}
        />
    </Switch>
);
