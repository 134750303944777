/* eslint-disable @typescript-eslint/no-explicit-any */

interface IThrottle {
    (callback: (...args: any[]) => void, delay?: number): (
        ...args: any[]
    ) => void;
}

export const throttle: IThrottle = (callback, delay = 400) => {
    let lastRan: number;
    let lastFunc: NodeJS.Timeout;

    return (...args: any[]): void => {
        if (!lastRan) {
            callback(...args);

            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);

            lastFunc = setTimeout(() => {
                if (Date.now() - lastRan >= delay) {
                    callback(...args);
                    lastRan = Date.now();
                }
            }, delay - (Date.now() - lastRan));
        }
    };
};
