import { FC, useMemo } from 'react';
import { IChildren, IRouteProps } from 'typings';
import { Link } from 'react-router-dom';
import styles from './layout.module.scss';
import contentStyles from '../layout/layout.content.module.scss';
import { useStoreScope } from 'store';
import { useClassName } from 'hooks';
import { Copyright } from 'pages/copyright';
import { Logo } from 'components/images-icons/logo';
import { sites, ISites } from './sites';

interface IWrapper extends IChildren, IRouteProps {
    fullPage?: boolean;
}

export const Wrapper: FC<IWrapper> = ({
    children,
    path: { controller },
}: IWrapper) => {
    const { visIndholdsfortegnelse } = useStoreScope('layout');

    const bottomClassName = useClassName(
        contentStyles.wrapper,
        visIndholdsfortegnelse && contentStyles.showTableOfContents
    );

    const { visibleSites, navigationBar } = useMemo((): {
        visibleSites: (Omit<ISites, 'undersider'> & { className: string })[];
        navigationBar?: (Omit<ISites, 'undersider'> & { className: string })[];
    } => {
        let navigationBar;

        const undercaseController = controller?.toLowerCase();

        const visibleSites = sites
            .filter(({ hidden }) => hidden !== true)
            .map((site) => {
                const isActive =
                    undercaseController === site.site ||
                    !!site.undersider?.some(
                        (underside) => undercaseController === underside.site
                    );

                if (isActive && site.undersider) {
                    navigationBar = site.undersider.map((underside) => ({
                        ...underside,
                        className: `${styles.navigationLink} ${
                            undercaseController === underside.site &&
                            styles.active
                        }`.trim(),
                    }));
                }

                return {
                    ...site,
                    className: `${styles.topMenuLink} ${
                        isActive && styles.active
                    }`.trim(),
                };
            });

        return { visibleSites, navigationBar };
    }, [controller]);

    return (
        <>
            <div className={styles.top}>
                <Link
                    to="/"
                    className={styles.logoWrapper}
                    aria-label="Forside"
                >
                    <Logo size="small" className={styles.logo} />
                </Link>
                {visibleSites.map(({ site, name, className }) => (
                    <Link key={site} to={`/${site}`} className={className}>
                        {name}
                    </Link>
                ))}
            </div>
            {!!navigationBar && (
                <div className={styles.navigation}>
                    {navigationBar?.map(({ site, name, className }) => (
                        <Link key={site} to={`/${site}`} className={className}>
                            {name}
                        </Link>
                    ))}
                </div>
            )}
            <div id="content" className={styles.content}>
                {children}
            </div>
            <div className={bottomClassName}>
                <div className={contentStyles.content}>
                    <Copyright />
                </div>
            </div>
        </>
    );
};
