export { v4 as generateId } from 'uuid';

export { classNames } from './classNames';
export { testString as matchTestString } from './match/testString';
export { capitalizeFirstLetter } from './string/capitalize-first-letter';

export { debounce } from './events/debounce';
export { throttle } from './events/throttle';

export { getElementRect } from './element/get-element-rect';
export { getElementDimensions } from './element/get-element-dimensions';
export { getScrollPosition } from './element/get-scroll-position';
export { isVisible } from './element/is-visible';
export { scrollToElement } from './element/scroll-to-element';

export * from './json';
export * from './local-storage';

export { filterUnique } from './array/filter/filerUnique';

export { sortBy, sortByKey } from './array/sort/sort-by';

export { delay } from './promise/delay';

export { getRandomNumber } from './random/number';
export { getRandomString, getRandomKey } from './random/string';
